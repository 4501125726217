.posts-page{
    display: grid;
    justify-content: center;
    font-family: 'Roboto Mono', monospace;
    grid-template-columns: 2fr 2fr 2fr;
}

.posts-page__cards{
    grid-column-start: 2;
    display: grid;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 20px;
}

.posts-page__title{
    grid-column-start: 2;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.posts-page__title h3{
    font-size: 20px;
    font-weight: 500;
}

.post-card-link{
    text-decoration: none;
    color: black;
}

.post-card-body{
    width: 200px;
    display: grid;
    /* flex-direction: column; */
    grid-template-rows: 2fr 0fr 0fr 0fr;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    justify-items: start;
    height: 100%;
}

.post-image :hover {
    border-top: 3px black solid;
}

.post-image{
    width: 100%;
}

.post-image img{
    width: 100%;
}

.post-card-text{
    padding-left: 11px;
    display: flex;
    flex-direction: column;
}

.post-card-text h1 {
    font-size: 16px;
}

.post-card-text p {
    font-size: 13px;
    display: none;
}

.post-card-footer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 5px;
    display: none;
}

.post-card-footer img {
    width: 30px;
    height: 28px;
}

.post-card-footer h2 {
    font-size: 12px;
    color: #5b5858;
}

.post-card-body button {
    border-radius: 10px;
    border: none;
    height: 23px;
    font-family: 'Roboto Mono';
    background: rgb(0 0 0);
    color: rgb(255 255 255);
    font-size: 12px;
    width: 71px;
    margin-left: 10px;
    margin-bottom: 5px;
    align-self: flex-start;
}

.post-card-body button a{
    text-decoration: none;
    color: white;
}

.posts-page a{
    text-decoration: none;
    color: black
}

.posts-page a:hover{
    border-top: 3px black solid;
}

@media screen and (max-width: 524px) {
    .posts-page {
        grid-template-columns: 1fr 2fr 1fr;
        justify-items: center;
    }

    .posts-page__title {
        grid-column-start: 2;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .posts-page__title h3 {
        font-size: 16px;
        font-weight: 500;
    }

    .posts-page__cards {
        grid-column-start: 2;
        display: flex;
        gap: 20px;
        flex-direction: column;
        width: 153px;
    }

    .post-card-body {
        width: 150px;
        display: grid;

    }

    .post-image{
        display: flex;
        justify-content: center;
    }

    .post-card-text{
        padding-left: 11px;
        display: flex;
        flex-direction: column;
    }

    .post-card-text h1 {
        font-size: 12px;
        font-weight: bolder;
    }

    .post-card-body button {
        font-size: 12px;
        width: 52px;
    }

    .post-image img {
        width: 100%;
    }


}