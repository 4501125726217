.all-projects-page {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 2fr 2fr 2fr;
	grid-template-columns: 2fr 2fr 2fr;
	font-family: "Roboto Mono";
}

.fprojects-card {
	-ms-grid-column: 2;
	grid-column-start: 2;
}

.all-projects-card {
	-ms-grid-row: 2;
	grid-row-start: 2;
	-ms-grid-column: 2;
	grid-column-start: 2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-top: 28px;
}

.all-projects-card h3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 10px;
}

.project-card {
	display: -ms-grid;
	display: grid;
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	justify-items: center;
	background-color: transparent;
	-webkit-perspective: none;
	perspective: none;
	width: 200px;
}

.project-card-body {
	width: 200px;
	display: -ms-grid;
	display: grid;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-grid-rows: 2fr 0fr 0fr 0fr;
	grid-template-rows: 2fr 0fr 0fr 0fr;
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
		0 6px 20px 0 rgba(0, 0, 0, 0.19);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-transition: -webkit-transform 0.2s;
	transition: -webkit-transform 0.2s;
	-o-transition: transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	/* background-color: #ffffff; */
	/* color: black; */
	position: relative;
}

.project-card-title {
	padding: 7px;
	font-size: 13px;
	text-transform: lowercase;
}

.project-card-description {
	font-size: 12px;
	padding: 7px;
}

.project-card-footer {
	-ms-grid-row: 4;
	grid-row-start: 4;
	padding: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.project-card-footer button {
	border-radius: 10px;
	border: none;
	height: 23px;
	font-family: "Roboto Mono";
	background: rgb(255, 255, 255);
	color: rgb(0, 0, 0);
	font-size: 12px;
	width: 110px;
	font-weight: bold;
}

.project-card-footer button a {
	text-decoration: none;
	color: black;
}

.project-card-text {
	line-height: 1px;
}

.project-card-img {
	display: -ms-grid;
	display: grid;
	width: 100%;
	/* padding: 5px; */
}

.project-card-img img {
	width: 95%;
	-ms-grid-column-align: center;
	justify-self: center;
}

.project-card-back {
	display: -ms-grid;
	display: grid;
	background-color: #050505;
	color: rgb(215 213 213);
	-webkit-transform: rotateY(180deg) translateZ(1px);
	transform: rotateY(180deg) translateZ(1px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	/* display:none; */
}

.project-card:hover .project-card-body {
	-webkit-transform: rotateY(180deg) translateZ(1px);
	transform: rotateY(180deg) translateZ(1px);
}

.project-card-body .project-card-back {
	position: absolute;
	width: 100%;
	height: 98%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	padding: 2px;
}

.project-card-back__details {
	padding-top: 3px;
	margin-left: 4px;
}

.project-card-back__details h3 {
	font-size: 20px;
	text-transform: lowercase;
	margin-top: 10px;
}

.project-card-back__details p {
	font-size: 11px;
}

.project-card-footer img {
	width: 23px;
	height: 22px;
}

.project-card-collection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 20px;
}

/* .featured-projects-header{
    grid-column: 1/3;
    margin-bottom: 10px;
}

.featured-projects-header h3 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.featured-projects-card {
    display: flex;
    grid-column-start: 2;
    gap: 20px;
    flex-direction: row-reverse;
    justify-content: flex-end;
} */

@media screen and (max-width: 425px) {
	.all-projects-page {
		display: -ms-grid;
		display: grid;
		justify-items: center;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.all-projects-card {
		display: -ms-grid;
		display: grid;
		justify-items: center;
		-ms-grid-column: 1;
		grid-column-start: 1;
	}

	.all-projects-card h3 {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		gap: 10px;
		font-weight: 300;
	}

	.project-card-collection {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.project-card-back__details p {
		display: none;
	}
	.fprojects-card {
		-ms-grid-column: 1;
		grid-column-start: 1;
		margin-top: 20px;
	}
	.project-card-body {
		width: 153px;
	}
	.project-card-title {
		font-size: 12px;
	}
}
