.footer-container {
	/* display: grid; */
	grid-template-columns: 2fr 3fr 3fr 2fr;
	margin-top: 30px;
	/* border-bottom-style: hidden; */
	font-size: 11px;
	width: 800px;
	/* justify-items: center; */
	justify-content: center;
	gap: 30px;
	background: #d9d9d9;
	height: 236px;
}

.footer-navigation {
	/* border-bottom: dashed; */
	/* width: 210px; */
	/* border-bottom-width: 50px; */
	border-bottom-width: thin;
	grid-column-start: 2;
	color: black;
}

.footer-navigation ul {
	border-top: 1px dotted #000000;
	padding-top: 10px;
}

.footer-navigation ul li a {
	text-decoration: none;
	color: black;
}

.footer-navigation a:hover {
	cursor: pointer;
	text-decoration: underline;
}

.footer-projects {
	/* border-bottom: dashed; */
	/* width: 210px; */
	/* border-bottom-width: 50px; */
	border-bottom-width: thin;
	color: black;
}

.footer-projects ul {
	border-top: 1px dotted #000000;
	padding-top: 10px;
}

.footer-projects ul li a {
	text-decoration: none;
	color: black;
}

.footer-projects a:hover {
	cursor: pointer;
	text-decoration: underline;
}

.footer-contact {
	border-bottom: dashed;
	/* width: 210px; */
	/* border-bottom-width: 50px; */
	border-bottom-width: thin;
	grid-column-start: 2;
	color: black;
}

.footer-socials {
	border-bottom-width: thin;
	grid-column-start: 2;
	color: black;
}

.footer-socials ul {
	border-top: 1px dotted #000000;
	margin-top: 10px;
}

.footer-socials ul li {
	display: flex;
	gap: 3px;
	padding: 0;
	align-items: center;
	margin: 4px;
}
.footer-socials ul a {
	text-decoration: none;
	color: black;
}

.footer-socials a:hover {
	cursor: pointer;
	text-decoration: underline;
}

.footer-content {
	display: flex;
	justify-content: space-evenly;
}

.footer-main {
	display: flex;
	justify-content: center;
}

.footer-content ul {
	list-style: none;
	padding: 0;
	color: black;
	margin: 0;
	padding-top: 4px;
}

#joel-big-logo {
	margin: 4px;
}

@media (max-width: 426px) {
	.footer-main {
		display: grid;
		width: 100%;
	}

	.footer-container {
		display: grid;
		height: 94%;
		width: 100%;
	}
	#joel-big-logo {
		grid-column-start: 1;
	}
	.footer-content {
		display: flex;
		justify-content: space-evenly;
		flex-direction: column;
		margin: 4px;
	}
}
