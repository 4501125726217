@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.about-me {
	background-color: #ffffff;
	display: grid;
	font-family: "Roboto Mono", monospace;
	margin-bottom: 2em;
}

.about-me-text {
	display: flex;
	border-radius: 20px;
	background: #728cea00;
	border: none;
	width: 151px;
	height: 41px;
	font-family: "Roboto Mono";
	font-size: larger;
	justify-content: space-evenly;
	align-items: center;
	font-weight: bold;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.about-me-title {
	display: grid;
	grid-template-rows: 0fr 0fr;
	line-height: 0px;
	margin-top: 20px;
	margin-left: 20px;
}

.about-me-title p {
	font-size: 11px;
	/* padding-left: 10px; */
	color: black;
	font-weight: bold;
	display: none;
}

.about-me-images {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
}

#joel-and-wife {
	grid-column-start: 2;
	width: 10em;
}

#joel-on-vacation {
	grid-column-start: 3;
	width: 9em;
	margin-left: -54px;
}

#joel-coffee {
	width: 10em;
	margin-left: -115px;
	margin-top: 14px;
}

.about-me-desc {
	display: grid;
	grid-template-rows: 2fr 0fr;
}

.about-me-desc-title {
	display: grid;
	grid-template-columns: 1fr 4fr 0.5fr;
	font-weight: 500;
}

.about-me-desc-title-par {
	font-size: 13px;
	grid-column-start: 1;
	margin-left: 20px;
	color: #4b4b4b;
	display: none;
}
.about-me-desc-title-text {
	grid-column-start: 2;
	line-height: 24px;
	color: whitesmoke;
	font-size: 13px;
	color: black;
}

@media (max-width: 425px) {
	.about-me {
		grid-column-start: 2;
		grid-row-start: 2;
		background-color: #ffffff;
		display: grid;
		font-family: "Roboto Mono", monospace;
		margin-bottom: 2em;
		justify-items: center;
	}

	.about-me-title {
		display: grid;
		grid-template-rows: 0fr 0fr;
		line-height: 0px;
		margin-top: 20px;
		margin-left: 20px;
	}

	.about-me-images {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		align-items: center;
		gap: 65px;
	}

	#joel-on-vacation {
		display: none;
	}

	.about-me-desc-title {
		display: grid;
		font-weight: 500;
		grid-template-columns: 0.5fr 4fr 0.5fr;
		padding: 19px;
	}

	.about-me-desc-title-par {
		display: none;
	}
}
