.links li {
	list-style: none;
	display: flex;
	align-items: center;
	gap: 5px;
}

.links ul {
	padding: 10px;
	display: grid;
	gap: 5px;
	justify-content: space-around;
}

.links a {
	text-decoration: none;
	color: rgb(0, 0, 0);
	cursor: pointer;
}
.links {
	grid-column: 1/2;
	position: absolute;
	right: 13px;
	top: 54px;
	background: #f9f8f8e0;
	width: 200px;
	height: 223px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	z-index: 1;
}
.links a :active {
	text-decoration: underline;
}
