.blog-container {
    display: grid;
    justify-content: center;
    font-family: 'Roboto Mono', monospace;
    grid-template-columns: 2fr 2fr 2fr;
}

.blog-body{
    grid-column-start: 2;
    margin-top: 25px;
}

.blog-header {
    display: grid;
}

.blog-header__hero h1 {
    font-size: 30px;
    margin: 0px;
}
.blog-header__hero img {
    grid-row-start: 2;
    justify-self: center;
}
.blog-header__hero-details {
    display: flex;
    align-items: center;
    gap: 10px;
}
.blog-header__hero-details img {
    width: 30px;
    height: 28px;
}
.blog-header__hero-details p{
    font-size: 13px;
}
.blog-image {
    grid-row-start: 2;
    justify-self: center;
    width: 62.5%;
}

.back-button{
    grid-column-start: 2;
}
.loading{
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-rows: 2fr 4fr;
    /* position: absolute; */
    display: grid;
    justify-items: center;
}
.spinner {
    grid-column-start: 2;
    grid-row-start: 2;
}

.blog-text img {
    width:100%
}

.blog-text p {
    font-size: 15px;
}

.blog-text pre{
    background: black;
    border-radius: 6px;
    font-size: 90%;
    padding: 10px;
}

@media (max-width: 426px) {
    .blog-container{
        display: grid;
        grid-template-columns: 2fr;
        padding: 40px;
    }

    .blog-text pre{
        font-size: 60%;
        padding: 10px;
    }
}

@media screen and (min-width:428px) and (max-width:1024px){
    .blog-container{
        grid-template-columns: 1fr 3fr 1fr;
        margin-top: 20px;
    }
}

@media screen and (min-width:1025px) {
    .blog-container{
        grid-template-columns: 2fr 2fr 2fr;
    }
}