@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.header {
	display: flex;
	font-family: "Roboto Mono", monospace;
	font-weight: 400;
	justify-content: center;
}

.navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 800px;
}

.navigation img {
	height: 25px;
}
.navigation ul {
	list-style: none;
	display: flex;
}
.navigation ul li {
	margin: 20px;
}
.navigation ul li a {
	text-decoration: none;
	color: black;
}

.navigation a:hover {
	cursor: pointer;
	text-decoration: underline;
}

.mobile-nav {
	display: none;
}

@media (max-width: 842px) {
	.navigation {
		display: none;
	}

	.mobile-nav {
		display: flex;
		background-color: rgb(255, 255, 255);
		margin-top: 20px;
		width: 100%;
		justify-content: center;
	}
}
