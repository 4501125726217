@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
.landing-page {
	display: grid;
	grid-template-columns: 2fr 2fr;
	grid-column-start: 2;
	grid-column-end: 3;
	margin-top: 20px;
	margin-bottom: 27px;
	justify-content: center;
	background: #fff;
	/* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
	gap: 20px;
}

#portfolio-image {
	/* margin-left: 168px; */
	grid-column-start: 1;
}

.container {
	display: grid;
	justify-content: center;
	font-family: "Roboto Mono", monospace;
	grid-template-columns: 2fr 2fr 2fr;
}

.landing-page-bio {
	display: grid;
	flex-direction: column;
	/* grid-template-rows: 1fr 1fr 1fr; */
}

.landing-page-bio h2 {
	color: #4b4b4b;
	font-family: Roboto Mono;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.4px;
	width: 445px;
	margin: 0;
	grid-row-start: 2;
}

.landing-page-actions {
	gap: 10px;
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 0fr 0fr;
	grid-row-start: 3;
	margin-top: 10px;
}

.landing-page-actions a {
	text-decoration: none;
	color: black;
}

.purple-button {
	display: flex;
	border-radius: 20px;
	border: none;
	width: 123px;
	height: 35px;
	font-family: "Roboto Mono";
	justify-content: center;
	align-items: center;
	grid-row-start: 1;
	gap: 10px;
	padding: 0;
	background: #d9d9d9;
}

.purple-button :hover {
	color: rgb(149, 143, 143);
}

.yellow-button {
	display: flex;
	border-radius: 20px;
	border: none;
	width: 157px;
	height: 35px;
	align-items: center;
	justify-content: center;
	grid-row-start: 1;
	font-family: "Roboto Mono", monospace;
	color: black;
	cursor: pointer;
	background: #d9d9d9;
}
.yellow-button :hover {
	color: rgb(149, 143, 143);
}
.about-me {
	display: grid;
	grid-column-start: 2;
	grid-row-start: 2;
	margin-bottom: 1em;
}

.featured-projects {
	grid-row-start: 3;
	grid-column-start: 2;
	display: grid;
	grid-template-columns: 0.5fr 2fr 0.5fr;
}

.connect-me {
	grid-row-start: 4;
	grid-column-start: 2;
	margin-top: 3em;
	margin-bottom: 4em;
	display: grid;
}

@media (max-width: 425px) {
	.container {
		display: grid;
		justify-content: center;
		font-family: "Roboto Mono", monospace;
	}

	.landing-page {
		display: grid;
		background: #fff;
		justify-items: center;
		grid-template-columns: 1fr;
		gap: 0px;
		margin-left: -14px;
	}

	#portfolio-image {
		width: 40%;
	}

	.landing-page-bio {
		display: grid;
		justify-items: center;
		grid-row-start: 2;
	}

	.landing-page-bio h2 {
		font-size: 90%;
		width: 74%;
		text-align: center;
		color: #4b4b4b;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: -0.4px;
		margin: 0;
	}

	.connect-me {
		grid-template-columns: 2fr;
		display: grid;
		justify-items: center;
	}

	.featured-projects {
		justify-items: center;
	}
}
