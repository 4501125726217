.connect-me-button{
    display: flex;
    border-radius: 20px;
    background: #ffffff;
    border: none;
    width: 123px;
    height: 35px;
    font-family: 'Roboto Mono';
    justify-content: space-evenly;
    align-items: center;
    grid-row-start: 1;
    font-size: larger;
    font-weight: bold;
    color: black
}

.connect-me-text {
    grid-column-start: 2;
    line-height: 24px;
    color: whitesmoke;
    font-size: 13px;
    color: #4B4B4B;
    grid-row-start: 2;
}

.connect-me-text p {
    line-height: 24px;
    color: whitesmoke;
    font-size: 13px;
    color: #000000;
    font-weight: 500;
}

.call-to-action {
    grid-row-start: 3;
    grid-column-start: 2;
    display: grid;
    flex-direction: column;
    grid-template-columns: 2fr;
    width: 200px;
    gap: 10px;
}

.call-to-action button{
    border-radius: 7px;
    background: #D9D9D9;
    mix-blend-mode: multiply;
    border: none;
    width: 185px;
    height: 52px;
    font-family: 'Roboto Mono', monospace;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    color: black
}

.call-to-action a{
    text-decoration: none;
    color: black
    
}

@media (max-width: 425px) {
    .connect-me-button{
        grid-column-start: 1;
    }
    .connect-me-text{
        grid-column-start: 1;
        display: grid;
        grid-template-columns: 0.2fr 1fr 0.2fr;
        justify-items: center;
    }

    .call-to-action{
        grid-column-start: 1;
    }

    .connect-me-text p{
        text-align: center;
        grid-column-start: 2;

    }
}