.mobile .logo {
    grid-row-start: 1;
}
.mobile{
    display: grid;
    width: 80%;
}

.menuIcon{
    grid-row-start: 1;
    justify-self: flex-end;
}

