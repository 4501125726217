.project-post{
    display: grid;
    /* grid-template-columns: 2fr 2fr 2fr; */
    /* grid-template-columns: 1fr 3fr 1fr; */
    grid-template-columns: 3fr 2fr 3fr;
}

.project-article {
    grid-column-start: 2;
}

.project-header-hero {
    display: grid
}

.project-header__hero-details {
    display: flex;
    justify-content: center;
}

.project-header__hero-details img{
    width: 300px;
}

.project-text p{
    font-size: 14px;
}

@media (max-width: 425px) {
    .project-post{
        display: grid;
        grid-template-columns: 2fr;
        padding: 40px;
    }

    .project-header__hero-details {
        display: flex;
        justify-content: center;
    }
    .project-header__hero-details img {
        width: 40%;
    }
}

@media screen and (min-width:428px) and (max-width:1024px){
    .project-post{
        grid-template-columns: 1fr 3fr 1fr;
        margin-top: 20px;
    }
}

@media screen and (min-width:1025px) {
    .project-post{
        grid-template-columns: 2fr 2fr 2fr;
    }
}