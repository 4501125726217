.card {
	display: -ms-grid;
	display: grid;
	-ms-grid-column: 2;
	grid-column-start: 2;
	-ms-grid-row: 2;
	grid-row-start: 2;
	justify-items: center;
	background-color: transparent;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	width: 200px;
}

.card-body {
	width: 200px;
	display: -ms-grid;
	display: grid;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-grid-rows: 2fr 0fr 0fr 0fr;
	grid-template-rows: 2fr 0fr 0fr 0fr;
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
		0 6px 20px 0 rgba(0, 0, 0, 0.19);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-transition: -webkit-transform 0.2s;
	transition: -webkit-transform 0.2s;
	-o-transition: transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	/* background-color: #ffffff; */
	/* color: black; */
	position: relative;
}

.card-title {
	padding: 7px;
	font-size: 13px;
	text-transform: lowercase;
}

.card-description {
	font-size: 12px;
	padding: 7px;
}

.card-footer {
	-ms-grid-row: 4;
	grid-row-start: 4;
	padding: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.card-footer button {
	border-radius: 10px;
	border: none;
	height: 23px;
	font-family: "Roboto Mono";
	background: rgb(255, 255, 255);
	color: rgb(0, 0, 0);
	font-size: 12px;
	width: 110px;
	font-weight: bold;
}

.card-text {
	line-height: 1px;
}

.card-img {
	display: -ms-grid;
	display: grid;
	width: 100%;
	/* padding: 5px; */
}

.card-img img {
	width: 95%;
	-ms-grid-column-align: center;
	justify-self: center;
}

.card-back {
	display: -ms-grid;
	display: grid;
	background-color: #050505;
	color: rgb(215 213 213);
	-webkit-transform: rotateY(180deg) translateZ(1px);
	transform: rotateY(180deg) translateZ(1px);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	/* display:none; */
}

.card:hover .card-body {
	-webkit-transform: rotateY(180deg) translateZ(1px);
	transform: rotateY(180deg) translateZ(1px);
}

.card-body .card-back {
	position: absolute;
	width: 100%;
	height: 98%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	padding: 2px;
}

.card-back__details {
	padding-top: 3px;
	margin-left: 4px;
}

.card-back__details h3 {
	font-size: 20px;
	text-transform: lowercase;
	margin-top: 10px;
}

.card-back__details p {
	font-size: 11px;
}

.card-footer img {
	width: 23px;
	height: 22px;
}

.featured-projects-header {
	-ms-grid-column: 1;
	-ms-grid-column-span: 2;
	grid-column: 1/3;
	margin-bottom: 10px;
}

.featured-projects-header h3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 10px;
}

.featured-projects-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-grid-column: 2;
	grid-column-start: 2;
	gap: 20px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-top: 10px;
}

@media (max-width: 425px) {
	.featured-projects-header {
		-ms-grid-column: 1;
		-ms-grid-column-span: 2;
		grid-column: 1/3;
		margin-bottom: 10px;
		-ms-grid-column-align: center;
		justify-self: center;
	}

	.card-body {
		width: 153px;
		display: -ms-grid;
		display: grid;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-grid-rows: 2fr 0fr 0fr 0fr;
		grid-template-rows: 2fr 0fr 0fr 0fr;
		-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
			0 6px 20px 0 rgba(0, 0, 0, 0.19);
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		-webkit-transition: -webkit-transform 0.2s;
		transition: -webkit-transform 0.2s;
		-o-transition: transform 0.2s;
		transition: transform 0.2s;
		transition: transform 0.2s, -webkit-transform 0.2s;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		position: relative;
	}

	.card-title {
		padding: 7px;
		font-size: 12px;
		text-transform: lowercase;
		font-weight: 200;
	}

	.card-back__details p {
		display: none;
	}
}
