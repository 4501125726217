.error-body{
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
}

.error-image{
    grid-column-start: 2;
}

.error-info{
    grid-column-start: 3;
}

.error-actions li {
    list-style-type:none;
    text-decoration: none;
}

.error-actions li:hover{
    content: 'icon';
    text-decoration: underline;
    cursor: pointer;
}
.error-actions a{
    color: black;
    text-decoration: none;
}

@media (max-width: 426px) {
    .error-body {
        display: grid;
        grid-template-columns: 2fr;
        grid-template-rows: 2fr 2fr;
        padding: 20px;
        align-items: center;
        justify-items: center;
    }
    .error-image{
        grid-row-start: 1;
    }
    .error-image img{
        width: 80%
    }
    .error-info{
        grid-row-start: 2;
        grid-column-start: 2;
    }
   
}

@media screen and (min-width:428px) and (max-width:1024px){

    .error-body {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        margin-top: 20px;
        justify-items: center;
    }
    .error-info {
        grid-column-start: 2;
        grid-row-start: 2;
    }
    .error-image img{
        width: 80%
    }
   
}

@media screen and (min-width:1025px) {
    .error-body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 3fr;
    }
}